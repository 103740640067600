/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import React from "react";

import hero from "../assets/hero.png";
import theme from "../themes";

import { mq, colors, typography } from "./styles";
import { useTranslation } from "./translation";

const DemoButton = styled(Link)({
  ":hover": {
    backgroundColor: theme.primaryDark,
  },
  backgroundColor: theme.primary,
  borderRadius: "0.4rem",
  color: theme.textPrimaryHigh,
  fontFamily: "Source Sans Pro, sans-serif",
  fontSize: "1.2rem",
  fontWeight: "600",
  padding: "0.4rem 0.8rem",
  transition: "background 120ms ease-in 0s",
});

const _strings = {
  en: {
    body: "Automate your communications with Gaston and save precious time.",
    cta: "Request a demo",
    gaston: "Gaston",
    header: "Your virtual business secretary.",
  },
  fr: {
    body:
      "Gagnez un temps précieux en automatisant vos communications avec Gaston.",
    cta: "Demander une démo",
    gaston: "Gaston",
    header: "Votre secrétaire virtuel intelligent.",
  },
};

const Hero = () => {
  const { strings } = useTranslation(_strings, "Hero");

  return (
    <div
      css={{
        alignItems: "center",
        backgroundColor: colors.pastel,
        display: "flex",
        flexDirection: "column",
        minHeight: "68vh",
        overflow: "hidden",
        padding: "2rem",
        [mq[1]]: {
          flexDirection: "row",
          justifyContent: "space-around",
          padding: "4rem",
        },
      }}
    >
      <div
        css={{
          alignItems: "flex-start",
          display: "flex",
          flex: 0.8,
          flexDirection: "column",
          margin: "0 0 2rem",
          [mq[1]]: { margin: "0 0 2rem" },
        }}
      >
        <div
          css={{
            ...typography.serif,
            color: theme.textBlackHigh,
            fontSize: "2rem",
            fontWeight: "bold",
            margin: "0 0 1rem",
          }}
        >
          {strings.header}
        </div>
        <div
          css={{
            color: theme.textBlackHigh,
            fontFamily: "Source Sans Pro, sans-serif",
            fontSize: "1.6rem",
            margin: "0 0 2rem",
          }}
        >
          {strings.body}
        </div>
        <DemoButton
          to="/demo"
          css={{
            alignSelf: "center",
            [mq[1]]: { alignSelf: "auto" },
          }}
        >
          {strings.cta}
        </DemoButton>
      </div>
      <img
        src={hero}
        alt=""
        css={{
          borderRadius: "0.4em",
          boxShadow:
            "-2rem -2rem 2.5rem rgba(255, 255, 255, 0.88), 1rem 1rem 4rem rgba(250, 0, 255, 0.1)",
          height: "auto",
          maxHeight: "605px",
          maxWidth: "342px",
          width: "100%",
        }}
      />
    </div>
  );
};

export default Hero;
