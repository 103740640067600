/** @jsx jsx */
import { jsx, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import React from "react";

import theme from "../themes";

import { typography, colors } from "./styles";

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(171, 71, 188, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(171, 71, 188, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(171, 71, 188, 0);
  }`;

const Button = styled(Link)({
  ...typography.sansSerif,
  ":hover": {
    backgroundColor: theme.primaryDark,
  },
  animation: `${pulse} 2s infinite`,
  backgroundColor: theme.primary,
  borderRadius: "0.4rem",
  color: theme.textPrimaryHigh,
  fontSize: "1.4rem",
  fontWeight: "600",
  padding: "0.5rem 1rem",
  textAlign: "center",
  transition: "background 120ms ease-in 0s",
});

const Banner = ({ className, body, to, cta }) => (
  <div
    className={className}
    css={{
      alignItems: "center",
      backgroundColor: colors.pastel,
      borderRadius: "0.4rem",
      boxShadow:
        "-18px -18px 36px #F3F3F3, 18px 18px 36px rgba(119, 55, 170, 0.1), inset 4px 4px 18px rgba(236, 236, 236, 0.5)",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      padding: "4rem 2rem",
    }}
  >
    <h3
      css={{
        ...typography.serif,
        fontSize: "1.8rem",
        fontWeight: "700",
        textAlign: "center",
      }}
    >
      {body}
    </h3>
    <Button to={to}>{cta}</Button>
  </div>
);

Banner.defaultProps = {
  body: "Vous aussi vous voulez gagner du temps ?",
  cta: "Demander une démo",
  to: "/demo",
};

export default Banner;
