/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";

import { typography, colors } from "./styles";

const Testimonial = ({ body, caption, className }) => (
  <div
    css={{
      alignItems: "center",
      backgroundColor: colors.pastel,
      borderRadius: "0.4rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "2rem 1rem",
    }}
    className={className}
  >
    <div
      css={{
        ...typography.sansSerif,
        "& span": {
          color: colors.strong,
        },
        fontSize: "1.4rem",
        fontWeight: "bold",
        marginBottom: "1rem",
        textAlign: "center",
      }}
    >
      <span>“</span>
      {body}
      <span>”</span>
    </div>
    <div
      css={{
        ...typography.sansSerif,
        color: "#2F2E41",
        fontSize: "1.2rem",
        fontWeight: "600",
      }}
    >
      {caption}
    </div>
  </div>
);

export default Testimonial;
