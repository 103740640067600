/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";

import { animations, typography } from "./styles";

const MediaBadge = ({ href, caption, className, image }) => (
  <a
    href={href}
    css={{
      ...animations.hoverHighlight,
      alignItems: "center",
      borderRadius: "6px",
      color: "#000",
      display: "flex",
      padding: "0.8rem 1.2rem",
      textDecoration: "none",
    }}
    className={className}
  >
    <img
      src={image || "https://via.placeholder.com/96x96"}
      alt={`Logo - ${caption}`}
      css={{
        borderRadius: "50%",
        height: "auto",
        marginRight: "2rem",
        maxWidth: "96px",
        width: "100%",
      }}
    />
    <div
      css={{
        fontSize: "1.4rem",
        fontWeight: "600",
        ...typography.sansSerif,
      }}
    >
      {caption}
    </div>
  </a>
);

export default MediaBadge;
