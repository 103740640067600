/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import LocalizedStrings from "react-localization";

import feature1 from "../assets/feature1.png";
import feature2 from "../assets/feature2.png";
import feature3 from "../assets/feature3.png";
import media1 from "../assets/media1.jpg";
import media2 from "../assets/media2.jpg";
import Banner from "../components/CTABanner";
import Feature from "../components/Feature";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import MediaBadge from "../components/MediaBadge";
import Testimonial from "../components/Testimonial";
import { typography, mq } from "../components/styles";
import { useTranslation } from "../components/translation";

const _strings = {
  en: {
    banner: {
      body: "Ready to reclaim your time?",
      cta: "Request a Demo",
    },
    feature1: {
      body:
        "When you miss a call, Gaston takes the message, and transcribes it for you. And it's just getting started.",
      title: "Voicemail transcription",
    },
    feature2: {
      body:
        "We work with you to connect Gaston to all the tools you use at work. You get a comprehensive view of your interactions with your contacts.",
      title: "Integrations",
    },
    feature3: {
      body:
        "On demand, we adapt Gaston to your workflows and create automations to save you time and augment your productivity.",
      title: "Automations",
    },
    media: "They talk about us",
    media1: {
      caption: "Europe 1 🇫🇷",
      href:
        "https://www.europe1.fr/emissions/la-france-bouge-academie/la-france-bouge-academie-221119-3932931",
    },
    media2: {
      caption: "Maddyness 🇫🇷",
      href: "https://www.maddyness.com/2019/10/29/application-gaston/",
    },
    testimonial1: {
      body:
        "Thanks to Gaston, calls are no longer a hassle and I can check if one is an emergency at a glance.",
      caption: "Sabrina Mansard, MD, Psychiatry",
    },
    testimonial2: {
      body:
        "Gaston allows us to reroute voicemails to the right manager, ready to be processed.",
      caption: "Benjamin Thillaye, Head of Property Management @ Oralia",
    },
    testimonial3: {
      body:
        "I spend a good part of the day on calls with candidates. Gaston allows me to know why someone's calling while I'm already on a call.",
      caption: "Amaury Eloy, Head of HR @ Sycomore AM",
    },
  },
  fr: {
    banner: {
      body: "Vous aussi vous voulez gagner du temps ?",
      cta: "Demander une démo",
    },
    feature1: {
      body:
        "Quand vous ne répondez pas, Gaston prend les messages de vos correspondants et les retranscrit immédiatement par écrit. Et ce n'est que le début.",
      title: "Prise des messages",
    },
    feature2: {
      body:
        "Nous travaillons avec vous pour intégrer Gaston à vos logiciels métier et à vos outils existants. Vous avez une vue complète sur vos interactions avec vos contacts.",
      title: "Interconnexion",
    },
    feature3: {
      body:
        "Selon vos besoins, nous adaptons Gaston à vos méthodes de travail et nous créons des automatisations pour vous faire gagner en efficacité.",
      title: "Automatisation",
    },
    media: "On parle de Gaston dans les médias",
    media1: {
      caption: "Europe 1",
      href:
        "https://www.europe1.fr/emissions/la-france-bouge-academie/la-france-bouge-academie-221119-3932931",
    },
    media2: {
      caption: "Maddyness",
      href: "https://www.maddyness.com/2019/10/29/application-gaston/",
    },
    testimonial1: {
      body:
        "Grâce à Gaston, je ne suis plus parasitée par mes appels et je peux voir si j’ai reçu un appel urgent.",
      caption: "Dr. Sabrina Mansard, Psychiatre",
    },
    testimonial2: {
      body:
        "Gaston nous permet de rediriger les messages vocaux de nos clients au bon gestionnaire, prêts à être traités.",
      caption: "Benjamin Thillaye, Responsable Service Copropriété chez Oralia",
    },
    testimonial3: {
      body:
        "Je passe une grosse partie de mes journées au téléphone avec nos candidats. Gaston me permet de savoir pourquoi quelqu'un m'appelle quand je suis déjà en ligne.",
      caption: "Amaury Eloy, Responsable Capital Humain chez Sycomore AM",
    },
  },
};

const HomeTestimonial = styled(Testimonial)({
  margin: "0 0 2rem",
});

const HomeFeature = styled(Feature)({
  margin: "0 0 2rem",
});

const HomeBanner = styled(Banner)({
  margin: "0 0 2rem",
});

const Home = ({ location }) => {
  const { strings, locale } = useTranslation(_strings, "Home");
  console.log("LOCALE IN HOME", locale);

  return (
    <Layout location={location}>
      <Hero />
      <div
        className="gaston-features"
        css={{
          padding: "2rem 1rem",
          [mq[1]]: {
            padding: "2rem 11vw",
          },
        }}
      >
        <HomeFeature
          body={strings.feature1.body}
          image={feature1}
          title={strings.feature1.title}
        />
        <HomeTestimonial
          body={strings.testimonial1.body}
          caption={strings.testimonial1.caption}
        />
        <HomeFeature
          body={strings.feature2.body}
          image={feature2}
          imagePosition="left"
          title={strings.feature2.title}
        />
        <HomeTestimonial
          body={strings.testimonial2.body}
          caption={strings.testimonial2.caption}
        />
        <HomeFeature
          body={strings.feature3.body}
          image={feature3}
          title={strings.feature3.title}
        />
        <HomeTestimonial
          body={strings.testimonial3.body}
          caption={strings.testimonial3.caption}
        />
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            margin: "0 0 2rem",
          }}
        >
          <h3
            css={{
              ...typography.serif,
              fontSize: "1.8rem",
              fontWeight: "700",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            {strings.media}
          </h3>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              [mq[0]]: {
                flexDirection: "row",
                justifyContent: "space-around",
              },
            }}
          >
            <MediaBadge
              caption={strings.media1.caption}
              href={strings.media1.href}
              image={media1}
              css={{
                flexGrow: 1,
                [mq[0]]: {
                  flexGrow: 0,
                },
              }}
            />
            <MediaBadge
              caption={strings.media2.caption}
              href={strings.media2.href}
              image={media2}
              css={{
                flexGrow: 1,
                [mq[0]]: {
                  flexGrow: 0,
                },
              }}
            />
          </div>
        </div>
        <HomeBanner
          body={strings.banner.body}
          caption={strings.banner.caption}
          to="/demo"
        />
      </div>
    </Layout>
  );
};

export default Home;
