/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";

import theme from "../themes";

import { mq, typography } from "./styles";

const Feature = ({ body, title, imagePosition, image, className }) => (
  <div
    css={{
      display: "flex",
      flexDirection: "column",
      [mq[1]]: {
        alignItems: "center",
        flexDirection: [
          "row",
          imagePosition === "left" && { flexDirection: "row-reverse" },
        ],
        justifyContent: "space-between",
      },
    }}
    className={className}
  >
    <div
      css={{
        alignItems: "flex-start",
        display: "flex",
        flex: 0.8,
        flexDirection: "column",
        marginBottom: "2rem",
        [mq[1]]: { marginBottom: 0 },
      }}
    >
      <h3
        css={{
          ...typography.serif,
          color: theme.textSurfaceHigh,
          fontSize: "1.6rem",
          fontWeight: "700",
          margin: "0 0 1rem",
        }}
      >
        {title}
      </h3>
      <p
        css={{
          ...typography.sansSerif,
          color: theme.textSurfaceHigh,
          fontSize: "1.2rem",
          fontWeight: "300",
          lineHeight: "1.2",
          margin: "0 0 1rem",
        }}
      >
        {body}
      </p>
    </div>
    <img
      src={image || "https://via.placeholder.com/500x350"}
      alt="Illustration de la fonctionnalité"
      css={{
        height: "auto",
        maxWidth: "500px",
        width: "100%",
      }}
    />
  </div>
);

export default Feature;
